<template>
  <div class="inner-container">
    <div class="section-head">
      <h2 class="section-title">Рейтинг игроков
        <span class="section-title__affix">{{getDisciplineName}}</span>
      </h2>
    </div>
    <!--        <ul class="icon-tabs-list">-->
    <!--          &lt;!&ndash;у элементов есть класс active, для подчеркивания&ndash;&gt;-->
    <!--          <li><img :src="require('@/img/disciplines-logo/counter-strike.png')" alt="#"></li>-->
    <!--          <li><img :src="require('@/img/disciplines-logo/fifa.png')" alt="#"></li>-->
    <!--          <li><img :src="require('@/img/disciplines-logo/nba.png')" alt="#"></li>-->
    <!--          <li><img :src="require('@/img/disciplines-logo/ufc.png')" alt="#"></li>-->
    <!--        </ul>-->
    <div class="tabs-container">
      <div class="single-tab">
        <ul class="sgl-players-list">
          <media :query="{maxWidth: 768}">
            <ul>
              <rating-of-participant v-for="rating in getRatingArray" :key="rating.id" :rating="rating" />
            </ul>
          </media>

          <media :query="{minWidth: 769}">
            <ul>
              <rating-of-participant v-for="rating in getFirstHalfRatingArray" :key="rating.id" :rating="rating"/>
            </ul>
          </media>

          <media :query="{minWidth: 769}">
            <ul>
              <rating-of-participant v-for="rating in getSecondHalfRatingArray" :key="rating.id" :rating="rating"/>
            </ul>
          </media>
        </ul>
      </div>
    </div>
<!--    <div class="sgl-button sgl-button&#45;&#45;grey sgl-button&#45;&#45;w100p">Показать еще</div>-->
  </div>
</template>

<script>
import RatingOfParticipant from "@/components/ratingOfParticipants/ratingOfParticipant/ratingOfParticipant";
import {mapGetters} from "vuex";
import Media from 'vue-media';

export default {
  name: "ratingOfEventGroups",
  components: {RatingOfParticipant, Media},
  props: {
    eventGroupId: String
  },

  computed: {
    ...mapGetters([
      'GET_RATING_EVENT_TOURNAMENT_GROUP',
      'GET_CURRENT_GAME_DISCIPLINE_IN_EVENT'
    ]),
    getRatingArray() {
      return this.GET_RATING_EVENT_TOURNAMENT_GROUP ?? []
    },

    getFirstHalfRatingArray() {
      const fhNumber = Number((this.getRatingArray.length / 2).toFixed());
      return this.getRatingArray.filter((_, index) => index < fhNumber)
    },

    getSecondHalfRatingArray() {
      const shNumber = Number((this.getRatingArray.length / 2).toFixed());
      return this.getRatingArray.filter((_, index) => index >= shNumber)
    },

    getDisciplineName() {
      return this.GET_CURRENT_GAME_DISCIPLINE_IN_EVENT;
    }
  },

  async created() {
    await this.SET_RATING_EVENT_TOURNAMENT_GROUP(this.eventGroupId);
  }
}
</script>

<style scoped>

</style>