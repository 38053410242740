<template>
  <div class="inner-container">
    <div class="section-head">
      <h2 class="section-title">турниры</h2>
    </div>
    <div class="sorting-panel">
      <ul class="text-tabs-list">
        <!--у элементов есть класс active, для подчеркивания-->
        <li @click="setFilterFuture" class="text-tabs-list-filter" :class="{'text-tabs-list-filter_selected': isFilterByFuture}">Будущие</li>
        <li @click="setFilterCurrent" class="text-tabs-list-filter" :class="{'text-tabs-list-filter_selected': isFilterByCurrent}">сейчас идут</li>
        <li @click="setFilterAll" class="text-tabs-list-filter" :class="{'text-tabs-list-filter_selected': isFilterByAll}">Все
          <media :query="{minWidth:526}">
            турниры
          </media>
          ({{ getAllCountOfEventGroupsTournaments }})
        </li>
      </ul>
      <div class="sorting-controls">
        <!--"ascending" класс svg для переключения состояния-->
        <svg class="sort-icon" :class="{'ascending': isSortByIncrease, '': isSortByDecrease}"
             @click="this.changeSortOrder"
             width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M5 8H19" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 12.5H16" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10 17H14" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="sgl-multiselect">
          сортировать по:
          <multiselect
              v-model="selectedSortOption"
              track-by="name"
              label="name"
              :options="selectOptions"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :preselect-first="true"
              :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{option}">
              {{ option.name }}
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6L8 10L12 6" stroke="#B0B0B0" stroke-linejoin="round"/>
              </svg>
            </template>
          </multiselect>
        </div>
      </div>
    </div>

    <div v-if="isHaveTournaments">
      <tournament-main-event-card :index="index" v-for="(tournament, index) in GET_EVENT_GROUPS_TOURNAMENTS" :tournament="tournament" :key="tournament.id"/>
      <div class="sgl-button sgl-button--grey sgl-button--w100p" v-if="!allDataIsLoaded" @click="setMoreEventGroupsTournaments">Показать еще</div>
    </div>

    <div v-else class="haveNotTournaments">
      <p>Турниры будут, но чуть позже.</p>
      <p>Можно посмотреть <span @click="setFilterAll" class="haveNotTournaments__allTournaments">Все турниры</span> по дисциплине.</p>
    </div>

  </div>
</template>

<script>

import Media from 'vue-media'
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters} from "vuex";
import TournamentMainEventCard from "@/components/TournamentMainEventCard/TournamentMainEventCard";

export default {
  name: "eventGroupTournaments",
  components: {TournamentMainEventCard, Media, Multiselect},
  data() {
    return {
      options: [{
        id: 0,
        name: 'Все'
      }],
      observer: null,
      selectedSortOption: '',
      selectOptions: [{
        name: 'дате начала',
        value: 'started_at'
      }]
    }
  },
  computed: {
    ...mapGetters([
      'GET_IS_HAVE_GROUPS_TOURNAMENTS',
      'GET_SELECTED_FILTER_FOR_EVENTS',
      'GET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS',
      'GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS',
      'GET_EVENT_GROUPS_TOURNAMENTS',
      'GET_IS_ALL_DATA_LOADED'
    ]),

    allDataIsLoaded() {
      return this.GET_IS_ALL_DATA_LOADED
    },

    getAllCountOfEventGroupsTournaments() {
      return this.GET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS
    },

    isFilterByAll() {
      return this.GET_SELECTED_FILTER_FOR_EVENTS.name === 'all'
    },

    isFilterByCurrent() {
      return this.GET_SELECTED_FILTER_FOR_EVENTS.name === 'current'
    },

    isFilterByFuture() {
      return this.GET_SELECTED_FILTER_FOR_EVENTS.name === 'future'
    },

    isSortByIncrease() {
      return this.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS === '+'
    },

    isSortByDecrease() {
      return this.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS === '-'
    },

    getUpcomingTournaments() {
      return this.GET_IS_HAVE_GROUPS_TOURNAMENTS
    },

    isHaveTournaments() {
      try {
        return this.getUpcomingTournaments.data.length !== 0
      } catch (e) {
        return []
      }
    },
  },

  methods: {
    ...mapActions([
      'SET_EVENT_GROUPS_TOURNAMENTS',
      'SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS',
      'SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS',
      'TOGGLE_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS',
      'SET_MORE_EVENT_GROUPS_TOURNAMENTS'
    ]),

    async setMoreEventGroupsTournaments() {
      await this.SET_MORE_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    async changeSortOrder() {
      this.TOGGLE_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS();
      await this.SET_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    async setFilterFuture() {
      this.SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS({name: 'future', value: '0,1'});
      await this.SET_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    async setFilterCurrent() {
      this.SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS({name: 'current', value: '4'});
      await this.SET_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    async setFilterAll() {
      this.SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS({name: 'all', value: null});
      await this.SET_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    setSelectedSort(selectedOption) {
      this.SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS(selectedOption.value)
    }
  },

  async mounted() {
    const eventId = this.$route.params.id;
    await this.SET_EVENT_GROUPS_TOURNAMENTS(eventId)
  }


}
</script>

<style scoped>

</style>