<template>
  <div class="sgl-events">
    <template v-if="isShow">
      <section class="content-section hero-banner-section">
        <top-game-banner-in-events/>
      </section>

      <active-stream title="Онлайн трансляция"/>

      <section class="content-section">
        <event-group-tournaments/>
      </section>

      <section class="content-section" v-if="this.GET_SPONSOR_BANNERS_FOR_MIDDLE_EVENT_GROUPS">
        <div class="inner-container">
          <banner-component :images="this.GET_SPONSOR_BANNERS_FOR_MIDDLE_EVENT_GROUPS" :link="sponsor.data.attributes.group_middle_banner_link"/>
        </div>
      </section>

<!--      <section class="content-section content-section&#45;&#45;gradient content-section&#45;&#45;p100">-->
<!--        <rating-of-event-groups :eventGroupId="this.$route.params.id" />-->
<!--      </section>-->

      <!--    <media :query="{minWidth:526}">
            <section class="content-section">
              <div class="inner-container">
                <div class="section-head">
                  <h2 class="section-title">Новости</h2>
                  <div class="ellipse-button">
                    смотреть все
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 5L16 12L9 19" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
                <slither-slider class="news-slider" :options="{
                  controls: false,
                  numberOfSlides: [
                  { number: 2, min: 0 },
                  { number: 3, min: 769 }],
                  animationEasing: 'easeInOutQuint',
                  animationDuration: 650,
                  gap: 25,
              }">
                  <div v-for="s in 9" :key="s" class="single-slide">
                    <img class="single-slide&#45;&#45;bg" :src="require('@/img/news-slide-bg.png')" alt="#">
                    <div class="single-slide&#45;&#45;content">
                      <div class="single-slide&#45;&#45;head">
                        <div class="sgl-label sgl-label&#45;&#45;colored">новости</div>
                        <div class="sgl-label">Вчера</div>
                      </div>
                      <div class="single-slide&#45;&#45;title">«У VP в матче c NoTechies не возникнет проблем» — прогнозы
                        Cybersport.ru
                        и «Рейтинга Букмекеров»
                      </div>
                    </div>
                  </div>
                </slither-slider>
              </div>
            </section>
          </media>
          <media :query="{maxWidth:525}">
            <section class="content-section">
              <div class="inner-container">
                <div class="section-head">
                  <h2 class="section-title">Новости</h2>
                </div>
                <div class="sgl-mobile-news">
                  <div v-for="s in 3" :key="s" class="single-slide">
                    <img class="single-slide&#45;&#45;bg" :src="require('@/img/news-slide-bg.png')" alt="#">
                    <div class="single-slide&#45;&#45;content">
                      <div class="single-slide&#45;&#45;head">
                        <div class="sgl-label sgl-label&#45;&#45;colored">новости</div>
                        <div class="sgl-label">Вчера</div>
                      </div>
                      <div class="single-slide&#45;&#45;title">«У VP в матче c NoTechies не возникнет проблем» — прогнозы
                        Cybersport.ru
                        и «Рейтинга Букмекеров»
                      </div>
                    </div>
                  </div>
                </div>
                <media :query="{maxWidth:525}">
                  <div class="ellipse-button">
                    смотреть все
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 5L16 12L9 19" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </media>
              </div>
            </section>
          </media>-->
    </template>

    <sgl-events-groups-skeleton v-else/>

    <scroll-top/>
  </div>
</template>

<script>
import ScrollTop from "@/components/UIElements/scrollTop";
import EventGroupTournaments from "@/components/eventGroupTournaments/eventGroupTournaments";
import RatingOfEventGroups from "@/components/ratingOfEventGroups/ratingOfEventGroups";
import {mapActions, mapGetters, mapState} from "vuex";
import TopGameBannerInEvents from "@/components/topGameBannerInEvents/topGameBannerInEvents";
import bannerComponent from "@/components/bannerComponent/bannerComponent";
import ActiveStream from "@/components/streams/activeStream/active-stream";
import SglEventsGroupsSkeleton from "@/views/sponsor-tournament/sglEventsGroups-skeleton";


export default {
  name: "sglEventGroups",
  components: {
    SglEventsGroupsSkeleton,
    TopGameBannerInEvents,
    // RatingOfEventGroups,
    EventGroupTournaments,
    ScrollTop,
    bannerComponent,
    ActiveStream,

  },
  data() {
    return {
      tournamentsListVisible: false,
      scrollButtonVisible: false,
      isShow: true,
    }
  },
  computed: {
    ...mapGetters([
      'GET_SPONSOR_BANNERS_FOR_MIDDLE_EVENT_GROUPS'
    ]),
    ...mapState({
      sponsor: state => state.sponsorInfo.sponsorInfo
    }),
  },
  methods: {
    ...mapActions([
      'SET_RATING_EVENT_TOURNAMENT_GROUP',
      'GET_DISCIPLINES',
      'SET_TOURNAMENT_GROUP',
      'SET_SPONSOR_INFO'
    ]),
    toggleList() {
      if (this.tournamentsListVisible) {
        this.$refs.listContainer.style.maxHeight = `0px`
        this.tournamentsListVisible = false
      } else {
        this.$refs.listContainer.style.maxHeight = `${this.$refs.listContainer.scrollHeight}px`
        this.tournamentsListVisible = true
      }
    },
    displayScrollButton() {
      this.scrollButtonVisible = window.scrollY >= window.innerHeight;
    },
    scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    throttle(func, ms) {
      let isThrottled = false,
          savedArgs,
          savedThis;

      function wrapper() {
        if (isThrottled) {
          savedArgs = arguments;
          savedThis = this;
          return;
        }
        func.apply(this, arguments);
        isThrottled = true;
        setTimeout(function () {
          isThrottled = false;
          if (savedArgs) {
            wrapper.apply(savedThis, savedArgs);
            savedArgs = savedThis = null;
          }
        }, ms);
      }

      return wrapper;
    },
  },
  created() {
    window.addEventListener("scroll", this.throttle(this.displayScrollButton, 128));
  },
  destroyed() {
    window.removeEventListener("scroll", this.throttle(this.displayScrollButton, 128));
  },
  async mounted() {
    this.isShow = false

    await this.GET_DISCIPLINES();

    // Получаю информацию о спонсоре
    await this.SET_SPONSOR_INFO('eventGroups');

    await this.SET_TOURNAMENT_GROUP(this.$route.params.id);

    this.isShow = true
  }
}
</script>

<style scoped lang="scss">

</style>
