<template>
  <div class="inner-container">
    <div class="event-banner">
      <img class="event-banner--bg" :src="getTopBanner" alt="#">
      <div class="event-banner--content">
        <div class="event-banner--title">{{getDisciplineName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
name: "topGameBannerInEvents",

  computed: {
    ...mapGetters([
        'GET_TOURNAMENT_GROUP',
        'GAMES'
    ]),

    getTopBanner() {
      try {
        return this.GET_TOURNAMENT_GROUP.data.topBanner
      } catch (e) {
        return ''
      }
    },

    getDiscipline() {
      try {
        return this.GAMES.find((game) => String(game.id) === String(this.GET_TOURNAMENT_GROUP.data.attributes['game_id']))
      } catch (e) {
        return null
      }
    },

    getDisciplineName() {
      try {
        return this.getDiscipline.attributes.name
      } catch (e) {
        return ''
      }
    },

    getDisciplineLogo() {
      try {
        return this.getDiscipline.logo
      } catch (e) {
        return ''
      }
    }
  },
}
</script>

<style scoped>

</style>