<template>
  <div>
    <div class="content-section hero-banner-section">
      <div class="inner-container" style="margin-top: 20px">
        <skelet-rectangle :width="9999999" :height="200"/>
      </div>
    </div>
    <div class="content-section">
      <div class="inner-container">
        <div class="inner-container">
          <skelet-rectangle :width="200" :height="40"/>
          <br>
          <br>
          <skelet-rectangle :width="9999999" :height="44"/>
          <br>
          <br>
          <skelet-rectangle :width="9999999" :height="200"/>
          <br>
          <skelet-rectangle :width="9999999" :height="200"/>
        </div>
      </div>
    </div>
    <section class="content-section content-section--gradient content-section--p100">
      <div class="inner-container">
        <skelet-rectangle :width="9999999" :height="200"/>
      </div>
    </section>
  </div>
</template>

<script>
import SkeletRectangle from "@/views/sceleton/components/rectangle";

export default {
  name: "sglEventsGroups-skeleton",
  components: {
    SkeletRectangle
  },

}
</script>

<style scoped lang="scss">
.rect {
  border-radius: 8px;
}
</style>